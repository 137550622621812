// LandingPage.js
import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,  // NOT used for sign-up now
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, functions } from "../../firebase";

import "./LandingPage.css";

// Images for the rotating slide show
import image1 from "./img1.png";
import image2 from "./img2.png";
import image3 from "./img3.png";

// If you have a service to fetch OpenAI status, import it:
import { fetchOpenAIStatus } from "./statusService";

// Rotating slides
const slides = [
  { src: image1, bgColor: "#6c757d" },
  { src: image2, bgColor: "#343a40" },
  { src: image3, bgColor: "#495057" },
];

// Example local services (just for UI display)
const initialLocalServices = [
  { name: "PDF Viewer", status: "Operational" },
  // Add more if needed
];

const LandingPage = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);
  const [error, setError] = useState("");
  const [isVersionPopupVisible, setIsVersionPopupVisible] = useState(false);
  const [isStatusPopupVisible, setIsStatusPopupVisible] = useState(false);
  const [lastChecked, setLastChecked] = useState(new Date());
  const [statusData, setStatusData] = useState({
    openAIStatus: "Unknown",
    openAIComponents: [],
    openAIIncidents: [],
    localServices: initialLocalServices,
  });
  const [statusError, setStatusError] = useState("");

  /**
   * Toggle which form is shown: login, signup, forgot
   */
  const handleToggleForm = (formType) => {
    setIsSignUp(formType === "signup");
    setIsForgotPassword(formType === "forgot");
    setError("");
  };

  /**
   * Sign In
   */
  /**
 * Sign In
 */
const handleSignIn = async (e) => {
  e.preventDefault();
  const email = e.target.email.value.trim();
  const password = e.target.password.value;
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const idTokenResult = await userCredential.user.getIdTokenResult(true);
    const claims = idTokenResult.claims;
    console.log("Custom claims:", claims);

    // Check if the user has recognized roles
    if (claims.student || claims.TF || claims.developer || claims.admin) {
      window.location.href = "/GPT"; // or your desired route
    } else {
      setError("Permissions invalid, you are on the waitlist.");
    }

  } catch (err) {
    console.error("Sign-in error:", err);
    // Check the specific error code for user-disabled
    if (err.code === "auth/user-disabled") {
      // Show your waitlist message
      setError("You've been added to a waitlist - this is a beta version so you will be accepted very shortly.");
    } else {
      // Otherwise, default error
      setError("Incorrect email or password. Please try again.");
    }
  }
};


  /**
   * Sign Up using the onCall function
   */
  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");

    const fullName = e.target.fullName.value.trim();
    const classCode = e.target.classCode.value.trim();
    const email = e.target.email.value.trim();
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (!fullName || !classCode || !email || !password || !confirmPassword) {
      setError("All fields (Name, Class Code, Email, Password) are required.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
      
    }
    console.log("handleSignUp => sending data:", {
      email,
      password,
      fullName,
      classCode
    });
    try {
      // 1) Call verifyClassCode
      const verifyClassCodeFn = httpsCallable(functions, "verifyClassCode");
      const result = await verifyClassCodeFn({
        email,
        password,
        fullName,
        classCode,
      });
      console.log("verifyClassCode result:", result.data);
      alert(result.data.message);

      // 2) If success, sign them in
      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = "/GPT";
    } catch (err) {
      console.error("Sign-up error:", err);
      setError(err.message || "Sign-up failed. Please try again.");
    }
  };

  /**
   * Slide rotation
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setIsProgressBarVisible(false);

      setTimeout(() => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        setIsTransitioning(false);
        setIsProgressBarVisible(true);
      }, 1000);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Load system status from fetchOpenAIStatus (if you have that service)
   */
  useEffect(() => {
    const loadStatus = async () => {
      const data = await fetchOpenAIStatus();
      if (data) {
        setStatusData((prev) => ({
          ...prev,
          openAIStatus: data.status.description,
          openAIComponents: data.components,
          openAIIncidents: data.incidents,
        }));
      } else {
        setStatusError("Unable to retrieve OpenAI status.");
        setStatusData((prev) => ({
          ...prev,
          openAIStatus: "Unknown",
        }));
      }
      setLastChecked(new Date());
    };

    loadStatus();

    // Refresh status every 5 minutes
    const statusInterval = setInterval(loadStatus, 300000);
    // Update "last checked" every minute
    const checkInterval = setInterval(() => {
      setLastChecked(new Date());
    }, 60000);

    return () => {
      clearInterval(statusInterval);
      clearInterval(checkInterval);
    };
  }, []);

  /**
   * Format the "last checked" time
   */
  const formatTime = (date) => {
    const now = new Date();
    const diffMs = now - date;
    const diffMins = Math.floor(diffMs / 60000);
    if (diffMins < 1) {
      return "Just now";
    } else if (diffMins === 1) {
      return "1 minute ago";
    } else {
      return `${diffMins} minutes ago`;
    }
  };

  /**
   * Compute overall status message
   */
  const computeStatusMessage = () => {
    const { openAIStatus, localServices } = statusData;
    let overall = "All systems operational";
    const issues = [];

    if (openAIStatus !== "All Systems Operational") {
      issues.push("OpenAI API");
    }
    localServices.forEach((service) => {
      if (service.status !== "Operational") {
        issues.push(service.name);
      }
    });
    if (issues.length > 0) {
      overall = `Core functionality operational (${issues.join(", ")} down)`;
    }
    return overall;
  };

  const overallStatusMessage = computeStatusMessage();
  const { src, bgColor } = slides[currentSlideIndex];

  return (
    <div className="landing-page">
      <div className="landing-card">
        {/* Left side (login forms) */}
        <div className="login-side">
          <div
            className={`form-container ${
              isSignUp ? "slide-left" : isForgotPassword ? "slide-right" : ""
            }`}
          >
            {/* LOGIN FORM */}
            <form className="login-form" onSubmit={handleSignIn}>
              <h2>Course Tutorbot</h2>
              <label>Email</label>
              <input
                name="email"
                type="email"
                placeholder="Your Harvard or MIT Email"
                required
              />
              <label>Password</label>
              <input
                name="password"
                type="password"
                placeholder="Your password"
                required
              />
              <div className="forgot-password-container">
                <span
                  onClick={() => handleToggleForm("forgot")}
                  className="forgot-password"
                >
                  Forgot password?
                </span>
              </div>
              <button type="submit" className="login-button">
                Sign In
              </button>
              <div className="create-account">
                <span
                  onClick={() => handleToggleForm("signup")}
                  className="create-account-link"
                >
                  New here? Create an account!
                </span>
              </div>
              {error && !isSignUp && !isForgotPassword && (
                <p className="error-message">{error}</p>
              )}
            </form>

            {/* SIGN-UP FORM */}
            <form className="signup-form" onSubmit={handleSignUp}>
              <h2>Waitlist Signup</h2>
              <label>Name</label>
              <input
                name="fullName"
                type="text"
                placeholder="Your Full Name"
                required
              />
              <label>Class Code</label>
              <input
                name="classCode"
                type="text"
                placeholder="Enter Class Code"
                required
              />
              <label>Email</label>
              <input
                name="email"
                type="email"
                placeholder="Your Harvard or MIT Email"
                required
              />
              <div className="password-fields">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  placeholder="Create a password"
                  required
                />
                <label>Confirm Password</label>
                <input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your password"
                  required
                />
              </div>
              <button type="submit" className="login-button">
                Sign Up
              </button>
              <div className="create-account">
                <span
                  onClick={() => handleToggleForm("login")}
                  className="create-account-link"
                >
                  Already have an account? Return.
                </span>
              </div>
              {error && isSignUp && <p className="error-message">{error}</p>}
            </form>

            {/* FORGOT PASSWORD FORM */}
            <form className="forgot-password-form" onSubmit={(e) => e.preventDefault()}>
              <h2>Reset Password</h2>
              <label>Email</label>
              <input
                name="email"
                type="email"
                placeholder="Your Harvard or MIT Email"
                required
              />
              <button type="submit" className="login-button">
                Send Recovery Email
              </button>
              <div className="create-account">
                <span
                  onClick={() => handleToggleForm("login")}
                  className="create-account-link"
                >
                  Remembered? Go back!
                </span>
              </div>
            </form>
          </div>
        </div>

        {/* Right side (rotating slides) */}
        <div
          className={`graphic-side ${isTransitioning ? "transitioning" : ""}`}
          style={{ backgroundColor: bgColor }}
        >
          <img src={src} alt="Slide" className="rotating-image" />
          {isProgressBarVisible && <div className="progress-bar"></div>}
        </div>
      </div>

      {/* VERSION FOOTER */}
      <div
        className="version-footer"
        onClick={() => setIsVersionPopupVisible(!isVersionPopupVisible)}
      >
        Version 1.0.1 - Build 20241113
      </div>
      {isVersionPopupVisible && (
        <div className="version-popup visible">
          <button
            className="close-popup"
            onClick={() => setIsVersionPopupVisible(false)}
          >
            X
          </button>
          <h3>Version History</h3>
          <p>
            <strong>Version 1.0.0 (Formerly 4.10) - Build 20241113</strong>
          </p>
          <p>Main Changes:</p>
          <p>- Addressing Major Outage. Fixed Formatting and Interface</p>
          <p>
            <strong>Version 0.9.9</strong>
          </p>
          <p>Main Changes:</p>
          <p>- Beta testing features, added new graphical interface, bug fixes.</p>
          <p>
            <strong>Version 0.9.8</strong>
          </p>
          <p>Main Changes:</p>
          <p>
            - First feature-complete build for alpha testers, including account
            creation and UI enhancements.
          </p>
        </div>
      )}

      {/* STATUS FOOTER */}
      <div
        className={`status-footer ${
          overallStatusMessage === "All systems operational"
            ? "status-active"
            : "status-inactive"
        }`}
        onClick={() => setIsStatusPopupVisible(!isStatusPopupVisible)}
      >
        <span className="status-text">Status: {overallStatusMessage}</span>
        <br />
        <span className="status-time">
          Last checked: {formatTime(lastChecked)}.
        </span>
      </div>
      {isStatusPopupVisible && (
        <div className="status-popup visible">
          <button
            className="close-popup"
            onClick={() => setIsStatusPopupVisible(false)}
          >
            X
          </button>
          <h3>System Status</h3>
          {statusError ? (
            <p className="error-message">{statusError}</p>
          ) : (
            <div>
              <p>
                <strong>OpenAI Status:</strong> {statusData.openAIStatus}
              </p>
              <p>
                <strong>Last Checked:</strong> {formatTime(lastChecked)}
              </p>
              <p>
                <strong>OpenAI Components:</strong>
              </p>
              <ul>
                {statusData.openAIComponents.map((component) => (
                  <li key={component.id}>
                    {component.name}: {component.status.replace("_", " ")}
                  </li>
                ))}
              </ul>
              {statusData.openAIIncidents.length > 0 && (
                <div>
                  <p>
                    <strong>Incidents:</strong>
                  </p>
                  <ul>
                    {statusData.openAIIncidents.map((incident) => (
                      <li key={incident.id}>{incident.name}</li>
                    ))}
                  </ul>
                </div>
              )}
              <p>
                <strong>Local Services:</strong>
              </p>
              <ul>
                {statusData.localServices.map((service) => (
                  <li key={service.name}>
                    {service.name}: {service.status}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LandingPage;
