// src/chatbot_components/MainInterface/Settings/UserPreferencesOverlay.js
import React from 'react';
import './UserPreferencesOverlay.css'; // We'll define next
import UserPreferences from '../UserPreferences'; // The actual logic

const UserPreferencesOverlay = ({ onClose }) => {
  // Close if user clicks the dark backdrop
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="overlay-card" onClick={handleOverlayClick}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <h2>User Preferences</h2>
        <UserPreferences />
      </div>
    </div>
  );
};

export default UserPreferencesOverlay;
