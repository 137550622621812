// src/chatbot_components/MainInterface/FloatingBar/WaitTimerBox.jsx

import React from 'react';
import './WaitTimerBox.css';

function WaitTimerBox({ waitTime, warnNoResponse }) {
  return (
    <div className="wait-timer-box">
      {warnNoResponse ? (
        <p className="wait-timer-text">
          No response after 30s.<br />
          Please try refreshing?
        </p>
      ) : (
        <p className="wait-timer-text">
          Waiting for response... {waitTime.toFixed(1)}s
        </p>
      )}
    </div>
  );
}

export default WaitTimerBox;
