// src/chatbot_components/MainInterface/Settings/UserPreferences.js
import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../firebase'; 
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

function UserPreferences() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);  // NEW: track loading state

  const [darkTheme, setDarkTheme] = useState(false);
  const [tutorialWatched, setTutorialWatched] = useState(false);
  const [requestLimit, setRequestLimit] = useState(10);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // As soon as we know there's a user, start loading prefs
        await loadPreferences(currentUser.uid);
      } else {
        setLoading(false); // No user -> nothing to load
      }
    });
    return () => unsubscribe();
  }, []);

  const loadPreferences = async (uid) => {
    setLoading(true); // Start loading
    try {
      const prefRef = doc(db, 'userPreferences', uid);
      const snapshot = await getDoc(prefRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        setDarkTheme(data.darkTheme ?? false);
        setTutorialWatched(data.tutorialWatched ?? false);
        setRequestLimit(data.requestLimit ?? 10);
      } else {
        // If no doc, create a default
        await setDoc(prefRef, {
          darkTheme: false,
          tutorialWatched: false,
          requestLimit: 10,
        });
      }
    } catch (error) {
      console.error('Error loading preferences:', error);
    } finally {
      setLoading(false); // Done loading regardless of success/failure
    }
  };

  const toggleDarkTheme = async () => {
    if (!user) return;
    try {
      const newDark = !darkTheme;
      setDarkTheme(newDark);
      await updateDoc(doc(db, 'userPreferences', user.uid), {
        darkTheme: newDark,
      });
    } catch (error) {
      console.error('Error toggling dark theme:', error);
    }
  };

  const toggleTutorialWatched = async () => {
    if (!user) return;
    try {
      const newTutorial = !tutorialWatched;
      setTutorialWatched(newTutorial);
      await updateDoc(doc(db, 'userPreferences', user.uid), {
        tutorialWatched: newTutorial,
      });
    } catch (error) {
      console.error('Error updating tutorialWatched:', error);
    }
  };

  const handleRequestLimitChange = async (delta) => {
    if (!user) return;
    try {
      const newLimit = requestLimit + delta;
      setRequestLimit(newLimit);
      await updateDoc(doc(db, 'userPreferences', user.uid), {
        requestLimit: newLimit,
      });
    } catch (error) {
      console.error('Error updating requestLimit:', error);
    }
  };

  if (!user) {
    // No user: we won't show preferences.
    return <p>Please log in to see your preferences.</p>;
  }

  if (loading) {
    // While loading, show a spinner or message
    return (
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <p>Loading your preferences...</p>
      </div>
    );
  }

  // Once done loading, show the preference UI
  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <strong>Dark Theme:</strong> {darkTheme ? 'Enabled' : 'Disabled'}
        <br />
        <button onClick={toggleDarkTheme}>
          {darkTheme ? 'Disable Dark Theme' : 'Enable Dark Theme'}
        </button>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <strong>Tutorial Watched:</strong> {tutorialWatched ? 'Yes' : 'No'}
        <br />
        <button onClick={toggleTutorialWatched}>
          Toggle Tutorial
        </button>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <strong>Request Limit:</strong> {requestLimit}
        <br />
        <button onClick={() => handleRequestLimitChange(1)}>+1</button>
        <button onClick={() => handleRequestLimitChange(-1)}>-1</button>
      </div>

      <p>These preferences are stored in Firestore under <code>userPreferences/&lt;uid&gt;</code>.</p>
    </div>
  );
}

export default UserPreferences;
