// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHtiG1iX7b1n5FDsc_QSrh1DQLaEFpE_A",
  authDomain: "harvard-physics-hosting.firebaseapp.com",
  projectId: "harvard-physics-hosting",
  storageBucket: "harvard-physics-hosting.appspot.com",
  messagingSenderId: "13367975000",
  appId: "1:13367975000:web:2b0e817ecb944ca9acbe2d",
  measurementId: "G-F0SFJPV585"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);


export { auth, db };
export const functions = getFunctions(app);


