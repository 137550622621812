// src/chatbot_components/MainInterface/SK.js

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Background,
} from 'react-flow-renderer';

import useChatbotSocket from '../Sockets/useChatbotSocket';
import getNodeTypesFn from './Nodes';
import WaitTimerBox from './FloatingBar/WaitTimerBox'; 

import Menu from './Menu/Menu';
import FloatingBar from './FloatingBar/FloatingBar';
import FileMenu from './Menu/FileMenu';
import CourseSelectorModal from './CourseSelectorModal';
import PrivacyPolicyOverlay from './Menu/PrivacyPolicyOverlay';

import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

import './CanvasFormatting/SidebarButtons/SidebarButtons.css';
import './CanvasFormatting/common/colors.css';

import visualData from './Nodes/data/visualData.json';

const nodeTypes = getNodeTypesFn();

/**
 * Helper to stack nodes top-to-bottom with some spacing
 */
function computeStackedPositions(nodes, reactFlowInstance, spacing = 50) {
  const mainNodes = nodes.filter((n) => n.type !== 'reference');
  const refNodes = nodes.filter((n) => n.type === 'reference');

  const sortedMain = [...mainNodes].sort((a, b) => a.position.y - b.position.y);

  let currentY = 0;
  for (let i = 0; i < sortedMain.length; i++) {
    const node = sortedMain[i];
    const measured = reactFlowInstance?.getNode(node.id);
    const nodeHeight = measured?.height || 80;
    sortedMain[i] = {
      ...node,
      position: { x: 100, y: currentY },
    };
    currentY += nodeHeight + spacing;
  }
  return [...sortedMain, ...refNodes];
}

function SK({
  userInfo = { firstName: 'User', lastName: '', roles: [], courses: [] },
}) {
  // --------------------
  // React Flow state
  // --------------------
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // --------------------
  // Collapsible menu
  // --------------------
  const [isFileMenuCollapsed, setFileMenuCollapsed] = useState(false);
  const isStudent = userInfo.roles.includes('student');

  // --------------------
  // User input & night mode
  // --------------------
  const [input, setInput] = useState('');
  const [isNightMode, setIsNightMode] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // --------------------
  // Overlays
  // --------------------
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showPrivacyOverlay, setShowPrivacyOverlay] = useState(false);

  // --------------------
  // File references
  // --------------------
  const [highlightedReferences, setHighlightedReferences] = useState([]);
  const [scrollToFileRef, setScrollToFileRef] = useState(null);

  // --------------------
  // Course data
  // --------------------
  const defaultClass =
    localStorage.getItem('selectedClass') || userInfo.courses[0] || 'default_class';
  const [selectedClass, setSelectedClass] = useState(defaultClass);

  const [courseData, setCourseData] = useState({
    menuImage: '',
    initialOptions: [],
    fileList: [],
  });
  const didFitRef = useRef(false);

  // States for waiting time.
 const [waitingForFirstResponse, setWaitingForFirstResponse] = useState(false);
 const [waitTime, setWaitTime] = useState(0);
 const [warnNoResponse, setWarnNoResponse] = useState(false);

   // NEW effect to increment `waitTime` while waiting
 useEffect(() => {
   let interval = null;
   if (waitingForFirstResponse) {
     interval = setInterval(() => {
       setWaitTime((prev) => {
         const newVal = prev + 0.1;
         if (newVal >= 30) {
           setWarnNoResponse(true);
         }
         return newVal;
       });
     }, 100);
   } else {
     // If not waiting, reset
     setWaitTime(0);
     setWarnNoResponse(false);
   }
   return () => {
     if (interval) clearInterval(interval);
   };
 }, [waitingForFirstResponse]);

useEffect(() => {
  if (reactFlowInstance && !didFitRef.current) {
    // Add a brief delay if you need node measurements first
    setTimeout(() => {
      reactFlowInstance.fitView({
        padding: 0.9,
        minZoom: 0.2,
        maxZoom: 1.5,
      });
      didFitRef.current = true; // Prevent subsequent recalculations
    }, 0);
  }
}, [reactFlowInstance]);

  const navigate = useNavigate();

  // For node and edge references
  const nextIdRef = useRef(1);
  const didInitRef = useRef(false);
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // --------------------
  // Track the selected prompt (for initial options)
  // --------------------
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  // --------------------
  // "Control Mode" for locking the canvas
  // --------------------
  const [isControlModeActive, setIsControlModeActive] = useState(false);
  const togglePressGuardRef = useRef(false);

  // Block default context menu
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }
    window.addEventListener('contextmenu', handleContextMenu, { passive: false });
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Key listener for toggling with *Control key only*
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Control' && !togglePressGuardRef.current) {
        setIsControlModeActive((prev) => !prev);
        togglePressGuardRef.current = true;
      }
    }
    function handleKeyUp(e) {
      if (e.key === 'Control') {
        togglePressGuardRef.current = false;
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Called by the ReferenceNode
  const handleReferenceClick = useCallback((refString) => {
    setScrollToFileRef(refString);
  }, []);

  // --------------------
  // addUserMessage
  // --------------------
  const addUserMessage = useCallback((messageText, isSuggested = false) => {
    if (!messageText.trim()) return;

    setHighlightedReferences([]);
    setScrollToFileRef(null);

    if (!isSuggested){ 
      setWaitingForFirstResponse(true); 
      setWaitTime(0); 
      setWarnNoResponse(false); 
    }

    if (socketRef.current) {
      socketRef.current.emit('add_message', {
        message: messageText,
        token: localStorage.getItem('token') || '',
      });
    }

    if (isSuggested) {
      setSelectedPrompt(messageText);
    }

    const userId = `user-${nextIdRef.current++}`;
    const botId = `bot-${nextIdRef.current++}`;

    // user node
    const userNode = {
      id: userId,
      type: 'user',
      data: { label: messageText },
      position: { x: 100, y: 9999 },
    };
    // chatbot node
    const botNode = {
      id: botId,
      type: 'chatbot',
      data: { label: '', isPlaceholder: true },
      position: { x: 100, y: 9999 },
    };

    setNodes((prev) => [...prev, userNode, botNode]);
    setEdges((prevEdges) => {
      const newEdges = [...prevEdges];
      if (isSuggested) {
        // connect from initial-options to user node
        newEdges.push({
          id: `e-initial-options->${userId}`,
          source: 'initial-options',
          sourceHandle: 'bottom',
          target: userId,
          targetHandle: 'top',
          type: 'smoothstep',
          animated: true,
        });
      }
      newEdges.push({
        id: `e${userId}-${botId}`,
        source: userId,
        target: botId,
        type: 'smoothstep',
        animated: true,
      });
      return newEdges;
    });

    if (!isSuggested) {
      setInput('');
      // setSelectedPrompt('__MANUAL__');
    }
  }, []);

  // --------------------
  // Create "fake" chatbot + VisualSelectionNode for "Visualize Functions"
  // --------------------
  const createVisualFlow = useCallback(() => {
    // 1) Make a chatbot node
    const chatId = `bot-${nextIdRef.current++}`;
    setNodes((prev) => [
      ...prev,
      {
        id: chatId,
        type: 'chatbot',
        data: {
          label:
            'Could you specify which functions or details you want to visualize? E.g., complex plane plots, real-valued surfaces, etc.',
          isPlaceholder: false,
        },
        position: { x: 100, y: 9999 },
      },
    ]);

    setEdges((prev) => [
      ...prev,
      {
        id: `e-init-visual->${chatId}`,
        source: 'initial-options',
        target: chatId,
        type: 'smoothstep',
        animated: true,
      },
    ]);

    // 2) Make the Available Visuals node
    const visualsId = `visual-selection-${nextIdRef.current++}`;
    setNodes((prev) => [
      ...prev,
      {
        id: visualsId,
        type: 'visualSelection',
        data: {
          visuals: visualData,
          selectedPrompt: null,
          onVisualSelect: (itemPrompt) => {
            // highlight the chosen card
            setNodes((oldNodes) =>
              oldNodes.map((nd) => {
                if (nd.id === visualsId) {
                  return {
                    ...nd,
                    data: {
                      ...nd.data,
                      selectedPrompt: itemPrompt,
                    },
                  };
                }
                return nd;
              })
            );
            // then do an addUserMessage
            addUserMessage(itemPrompt, false);
          },
        },
        position: { x: 100, y: 9999 },
      },
    ]);

    setEdges((prev) => [
      ...prev,
      {
        id: `e${chatId}-${visualsId}`,
        source: chatId,
        target: visualsId,
        type: 'smoothstep',
        animated: true,
      },
    ]);
  }, [addUserMessage, visualData]);

  // --------------------
  // Socket logic
  // --------------------
  const handleSocketResponse = useCallback(
    (data) => {
      console.log('[Socket] final_response =>', data);
      if (data.text_delta && data.text_delta.length > 0) {
          setWaitingForFirstResponse(false);
      }
      // update chatbot node text
      setNodes((prev) => {
        const updated = [...prev];
        for (let i = updated.length - 1; i >= 0; i--) {
          if (updated[i].type === 'chatbot' && updated[i].data.isPlaceholder) {
            updated[i] = {
              ...updated[i],
              data: {
                ...updated[i].data,
                label: updated[i].data.label + (data.text_delta || ''),
                isPlaceholder: !data.done,
              },
            };
            break;
          }
        }
        nodesRef.current = updated;
        return updated;
      });

      // If done & citations => reference node
      if (data.done && Array.isArray(data.citations) && data.citations.length > 0) {
        setHighlightedReferences(data.citations);

        const chatbotNodes = nodesRef.current.filter((n) => n.type === 'chatbot');
        const lastBot = chatbotNodes[chatbotNodes.length - 1];
        if (!lastBot || !reactFlowInstance) return;

        const measured = reactFlowInstance.getNode(lastBot.id);
        if (!measured) return;

        const refNodeId = `ref-${nextIdRef.current++}`;
        const fallbackWidth = measured?.width || 400;
        const pos = {
          x: measured.position.x + fallbackWidth + 50,
          y: measured.position.y - 30,
        };

        const refNode = {
          id: refNodeId,
          type: 'reference',
          data: {
            references: data.citations,
            onReferenceClick: handleReferenceClick,
          },
          position: pos,
        };

        setNodes((prev) => [...prev, refNode]);
        setEdges((prevEdges) => [
          ...prevEdges,
          {
            id: `e${lastBot.id}-${refNodeId}`,
            source: lastBot.id,
            target: refNodeId,
            type: 'smoothstep',
            animated: true,
          },
        ]);
      }
    },
    [reactFlowInstance, handleReferenceClick]
  );

  const handleSuggestedQuestions = useCallback((data) => {
    console.log('[Socket] suggested_questions =>', data);
  }, []);

  const handleVisualizationData = useCallback((data) => {
    console.log('[Socket] visualization_data =>', data);
    if (data.visualization_type === 'riemann_surface') {
      setNodes((prev) => {
        const newNodeId = `viz-${nextIdRef.current++}`;
        return [
          ...prev,
          {
            id: newNodeId,
            type: 'riemannSurfaceNode',
            data: { plotData: data.plot_data },
            position: { x: 100, y: 9999 },
          },
        ];
      });
    }
    
    else if (data.visualization_type === 'mobius') {
      setNodes((prev) => {
        const newNodeId = `viz-${nextIdRef.current++}`;
        return [
          ...prev,
          {
            id: newNodeId,
            type: 'mobiusNode',
            data: {
              plotData: data.plot_data
            },
            position: { x: 100, y: 9999 },
          },
        ];
      });
    } else if (data.visualization_type === 'nyquist') {
      setNodes((prev) => {
        const newNodeId = `viz-${nextIdRef.current++}`;
        return [...prev, {
          id: newNodeId,
          type: 'nyquistNode',
          data: { plotData: data.plot_data },
          position: { x: 100, y: 9999 },
        }];
      });
    } else if (data.visualization_type === 'conformal') {
      setNodes((prev) => {
        const newNodeId = `viz-${nextIdRef.current++}`;
        return [...prev, {
          id: newNodeId,
          type: 'conformalNode',
          data: { plotData: data.plot_data },
          position: { x: 100, y: 9999 },
        }];
      });
    } 
    // RETIRING THIS 
    // else if (data.visualization_type === 'branch_cut') {
    //   // branchCutVisualization node
    //   setNodes((prev) => {
    //     const newNodeId = `viz-${nextIdRef.current++}`;
    //     return [...prev, {
    //       id: newNodeId,
    //       type: 'branchCutVisualization',
    //       data: {
    //         plotData: data.plot_data,
    //         equation: data.funcExpression || 'log(z)',
    //         plotType: '2D',
    //       },
    //       position: { x: 100, y: 9999 },
    //     }];
    //   });
    // }
    // e.g. in handleVisualizationData
else if (data.visualization_type === 'contour_and_poles') {
  setNodes((prev) => {
    const newNodeId = `viz-${nextIdRef.current++}`;
    return [
      ...prev,
      {
        id: newNodeId,
        type: 'contourAndPoleNode',
        data: {
          plotData: data.plot_data,
          function: data.plot_data.function  // ensure we store the function
        },
        position: { x: 100, y: 9999 },
      },
    ];
  });
}

     else if (data.visualization_type === 'complex_function_plotter') {
      // domainColorPlotter node
      setNodes((prev) => {
        const newNodeId = `viz-${nextIdRef.current++}`;
        return [...prev, {
          id: newNodeId,
          type: 'domainColorPlotter',
          data: {
            plotData: data.plot_data,
            funcExpression: data.funcExpression || '',
          },
          position: { x: 100, y: 9999 },
        }];
      });
    } // inside handleVisualizationData in SK.js:
    
    
    
    else {
      // fallback if none matched
      console.warn('Unknown visualization type:', data.visualization_type);
    }
  }, []);
  


  const socketRef = useChatbotSocket({
    selectedClass,
    handleSocketResponse,
    handleSuggestedQuestions,
    handleVisualizationData,
  });

  // --------------------
  // Layout
  // --------------------
  const repositionNodes = useCallback(() => {
    if (!reactFlowInstance) return;
    setNodes((prev) => computeStackedPositions(prev, reactFlowInstance, 50));
  }, [reactFlowInstance]);

  useEffect(() => {
    if (!reactFlowInstance) return;
    const t = setTimeout(() => repositionNodes(), 60);
    return () => clearTimeout(t);
  }, [nodes, edges, reactFlowInstance, repositionNodes]);

  // Insert isControlModeActive into domainColorPlotter
  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.type === 'domainColorPlotter') {
          return {
            ...node,
            data: {
              ...node.data,
              isCmdKeyHeld: isControlModeActive,
            },
          };
        } else if (node.type === 'mobiusNode') {
          return {
            ...node,
            data: {
              ...node.data,
              isCmdKeyHeld: isControlModeActive,
            },
          };
        }
        return node;
      })
    );
  }, [isControlModeActive]);
  

  // --------------------
  // Load course JSON
  // --------------------
  useEffect(() => {
    async function loadCourseJson() {
      try {
        if (selectedClass === 'physics15a') {
          const data = await import('./Nodes/data/physics15a.json');
          setCourseData(data.default);
        } else if (selectedClass === 'am104') {
          const data = await import('./Nodes/data/AM104.json');
          setCourseData(data.default);
        }
      } catch (err) {
        console.error('Error loading course JSON:', err);
      }
    }
    loadCourseJson();
  }, [selectedClass]);

  // --------------------
  // Initialize the initial-options node
  // --------------------
  useEffect(() => {
    if (didInitRef.current) return;
    didInitRef.current = true;

    setNodes([
      {
        id: 'initial-options',
        type: 'initialOptions',
        data: {
          onOptionClick: (txt) => {
            // If user selects "Visualize Functions", highlight it & create the special flow
            if (txt === 'Visualize Functions') {
              setSelectedPrompt(txt);
              createVisualFlow();
            } else {
              addUserMessage(txt, true);
            }
          },
          imagesArray: [],
          selectedPrompt: null,
        },
        position: { x: 100, y: 0 },
      },
    ]);
    setEdges([]);
    nextIdRef.current = 1;
  }, [addUserMessage, createVisualFlow]);

  // Whenever courseData or selectedPrompt changes, update initial-options node's data
  useEffect(() => {
    const forcedReloadOptions = (courseData.initialOptions || []).map((item) => ({
      ...item,
      src: item.src ? `${item.src}?cb=${Date.now()}` : item.src,
    }));

    setNodes((prev) =>
      prev.map((n) => {
        if (n.id === 'initial-options') {
          return {
            ...n,
            data: {
              ...n.data,
              onOptionClick: (txt) => {
                if (txt === 'Visualize Functions') {
                  setSelectedPrompt(txt);
                  createVisualFlow();
                } else {
                  addUserMessage(txt, true);
                }
              },
              imagesArray: forcedReloadOptions,
              selectedPrompt: selectedPrompt,
            },
          };
        }
        return n;
      })
    );
  }, [courseData, addUserMessage, createVisualFlow, selectedPrompt]);

  // Keep refs in sync
  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  // --------------------
  // Refresh
  // --------------------
  const refreshConversation = useCallback(() => {
    setHighlightedReferences([]);
    setScrollToFileRef(null);
    setSelectedPrompt(null);

    const forcedReloadOptions = (courseData.initialOptions || []).map((item) => ({
      ...item,
      src: item.src ? `${item.src}?cb=${Date.now()}` : item.src,
    }));

    setNodes([
      {
        id: 'initial-options',
        type: 'initialOptions',
        data: {
          onOptionClick: (txt) => {
            if (txt === 'Visualize Functions') {
              setSelectedPrompt(txt);
              createVisualFlow();
            } else {
              addUserMessage(txt, true);
            }
          },
          imagesArray: forcedReloadOptions,
          selectedPrompt: null,
        },
        position: { x: 100, y: 0 },
      },
    ]);
    setEdges([]);
    nextIdRef.current = 1;
  }, [addUserMessage, createVisualFlow, courseData]);

  // --------------------
  // Night Mode & Logout
  // --------------------
  const toggleNightMode = () => setIsNightMode((prev) => !prev);

  const logout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const openPrivacyOverlay = useCallback(() => {
    setShowPrivacyOverlay(true);
  }, []);

  // --------------------
  // Course Selector
  // --------------------
  useEffect(() => {
    const storedClass = localStorage.getItem('selectedClass');
    if (!storedClass) {
      setShowCourseModal(true);
    }
  }, [userInfo.courses]);

  const handleCourseSelected = (courseName) => {
    localStorage.setItem('selectedClass', courseName);
    setSelectedClass(courseName);
    window.location.reload();
  };

  // --------------------
  // .flow-wrapper ref
  // --------------------
  const flowWrapperRef = useRef(null);

  useEffect(() => {
    const flowWrapper = flowWrapperRef.current;
    if (!flowWrapper) return;

    function maybeBlockWheel(e) {
      if (isControlModeActive) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    function maybeBlockGesture(e) {
      if (isControlModeActive) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    flowWrapper.addEventListener('wheel', maybeBlockWheel, { passive: false });
    flowWrapper.addEventListener('gesturestart', maybeBlockGesture, { passive: false });
    flowWrapper.addEventListener('gesturechange', maybeBlockGesture, { passive: false });

    return () => {
      flowWrapper.removeEventListener('wheel', maybeBlockWheel);
      flowWrapper.removeEventListener('gesturestart', maybeBlockGesture);
      flowWrapper.removeEventListener('gesturechange', maybeBlockGesture);
    };
  }, [isControlModeActive]);

  // --------------------
  // Render
  // --------------------
  return (
    <div className={`newgpt-container ${isNightMode ? 'night-mode' : ''}`}>
      <ReactFlowProvider>
        <div className="flow-wrapper" ref={flowWrapperRef}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onInit={setReactFlowInstance}
            onConnect={(params) => setEdges((eds) => addEdge(params, eds))}
            panOnDrag={!isControlModeActive}
            panOnScroll={!isControlModeActive}
            zoomOnScroll={!isControlModeActive}
            zoomOnPinch={!isControlModeActive}
            fitView
            className="reactflow-background"
          >
            <Background color="#cacaca" gap={14} variant="dots" />
          </ReactFlow>
        </div>
      </ReactFlowProvider>

      <Menu
        userInfo={userInfo}
        selectedClass={courseData.officialName}
        showSettingsModal={showSettingsModal}
        setShowSettingsModal={setShowSettingsModal}
        openPrivacyOverlay={openPrivacyOverlay}
        logout={logout}
        isNightMode={isNightMode}
        menuImage={courseData.menuImage}
        switchCourse={() => setShowCourseModal(true)}
      />

      {showPrivacyOverlay && (
        <PrivacyPolicyOverlay onClose={() => setShowPrivacyOverlay(false)} />
      )}
      {showCourseModal && (
        <CourseSelectorModal
          courses={userInfo.courses}
          onCourseSelected={handleCourseSelected}
          onClose={() => setShowCourseModal(false)}
        />
      )}

{!isStudent && (
  <FileMenu
    data={courseData.fileList}
    selectedClass={courseData.officialName}
    highlightedReferences={highlightedReferences}
    scrollToFileRef={scrollToFileRef}
    isCollapsed={isFileMenuCollapsed}
    setCollapsed={setFileMenuCollapsed}
  />
)}

<div className="menu-toggle-button-container">
  <button
    className="menu-toggle-button"
    disabled={isStudent}
    style={isStudent ? { backgroundColor: 'grey', cursor: 'not-allowed' } : {}}
    onClick={() => {
      if (isStudent) {
        // Do nothing or show a quick alert
        alert('File menu is coming soon for students!');
      } else {
        setFileMenuCollapsed((prev) => !prev);
      }
    }}
  >
    {isStudent
      ? 'File Menu Coming Soon'
      : (isFileMenuCollapsed ? 'Open Menu' : 'Close Menu')}
  </button>
</div>


      {isControlModeActive && (
        <div
          style={{
            position: 'fixed',
            bottom: '75px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffea99',
            padding: '8px 12px',
            borderRadius: '6px',
            fontWeight: 'bold',
            boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
            zIndex: 9999,
          }}
        >
          Control Mode is ACTIVE – Canvas locked. Press Control again to unlock.
        </div>
      )}

      <FloatingBar
        input={input}
        setInput={setInput}
        addUserMessage={(msg) => addUserMessage(msg, false)}
        onRefresh={refreshConversation}
        isLocked={isControlModeActive}
      />

      {waitingForFirstResponse && (
            <WaitTimerBox 
              waitTime={waitTime} 
              warnNoResponse={warnNoResponse}
            />
          )}

    </div>


  );
}

export default SK;
