// FileMenu.js
import React, { useState, useEffect, useRef } from 'react';
import './FileMenu.css';
import fileData from './fileData.json';
import fileData2 from './fileData2.json';
import PedagogicalOutcomesOverlay from './PedagogicalOutcomesOverlay';
import MinimalPDFViewer from './MinimalPDFViewer';
import { partialMatch } from '../../utils/matching';

/**
 * FileMenu Component:
 * - Renders pinned files and categorized files in a sidebar.
 * - Uses version control: for the course "APMTH 104", the original file menu behavior is used;
 *   for other courses, updated features (pedagogical overlay, topic relabeling,
 *   clear outcomes placeholder) are applied.
 */
function FileMenu({
  data,
  highlightedReferences = [],
  scrollToFileRef,
  isCollapsed,
  selectedClass,
  setCollapsed,
}) {
  // Determine fallback file data.
  let fallbackData = {
    pinnedFiles: fileData2.pinnedFiles || [],
    categories: fileData2.categories || [],
  };

  if (selectedClass === 'APMTH 104') {
    fallbackData = {
      pinnedFiles: fileData.pinnedFiles || [],
      categories: fileData.categories || [],
    };
  } else if (selectedClass === 'physics15a') {
    fallbackData = {
      pinnedFiles: fileData2.pinnedFiles || [],
      categories: fileData2.categories || [],
    };
  }

  const finalData =
    !data || !data.pinnedFiles || !data.categories
      ? fallbackData
      : data;

  // Use updated file menu features if the course is NOT "APMTH 104".
  const useUpdatedFileMenu = selectedClass !== 'APMTH 104';

  const [searchTerm, setSearchTerm] = useState('');
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [overlayFile, setOverlayFile] = useState(null);
  const [showPedagogyOverlay, setShowPedagogyOverlay] = useState(false);
  const fileRefs = useRef({});

  // Helper function to relabel "Week" to "Topic" if using the updated file menu.
  const getTopicTitle = (weekTitle, weekIdx) => {
    return useUpdatedFileMenu && weekTitle.startsWith('Week')
      ? weekTitle.replace(/Week\s*\d+/, `Topic ${weekIdx + 1}`)
      : weekTitle;
  };

  // File click handler.
  // If using the updated file menu and the file is "Pedagogical Outcomes",
  // open the dedicated overlay; otherwise, open the generic overlay.
  const handleFileClick = (file) => {
    if (useUpdatedFileMenu && file.name === 'Pedagogical Outcomes') {
      setShowPedagogyOverlay(true);
    } else {
      setOverlayFile(file);
    }
  };

  // Determines if a file should be highlighted.
  const shouldHighlight = (fileName, fileLink) => {
    return highlightedReferences.some((ref) => {
      return partialMatch(ref, fileName) || partialMatch(ref, fileLink);
    });
  };

  // Check if a file matches the scrollToFileRef.
  const doesMatchScrollRef = (fileName, fileLink) => {
    if (!scrollToFileRef) return false;
    return partialMatch(scrollToFileRef, fileName) || partialMatch(scrollToFileRef, fileLink);
  };

  // Toggle expansion of a week/topic section.
  const toggleWeek = (catIdx, weekIdx) => {
    const key = `${catIdx}-${weekIdx}`;
    setExpandedWeeks((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  // Filter files based on the search term.
  const matchesSearch = (fileName) => {
    return fileName.toLowerCase().includes(searchTerm.toLowerCase());
  };

  // When scrollToFileRef changes, expand and scroll to the matching file.
  useEffect(() => {
    if (!scrollToFileRef) return;
    setCollapsed(false);
    let foundKey = null;

    // Check pinned files.
    for (const pinned of finalData.pinnedFiles) {
      if (doesMatchScrollRef(pinned.name, pinned.link)) {
        foundKey = `pinned-${pinned.id}`;
        break;
      }
    }

    // Otherwise, check categorized files.
    if (!foundKey) {
      categoryLoop: for (let cIdx = 0; cIdx < finalData.categories.length; cIdx++) {
        const cat = finalData.categories[cIdx];
        for (let wIdx = 0; wIdx < cat.weeks.length; wIdx++) {
          const wk = cat.weeks[wIdx];
          for (let fIdx = 0; fIdx < wk.files.length; fIdx++) {
            const fi = wk.files[fIdx];
            if (doesMatchScrollRef(fi.name, fi.link)) {
              const key = `${cIdx}-${wIdx}`;
              setExpandedWeeks((prev) => ({ ...prev, [key]: true }));
              foundKey = `cat-${cIdx}-week-${wIdx}-file-${fIdx}`;
              break categoryLoop;
            }
          }
        }
      }
    }

    if (foundKey && fileRefs.current[foundKey]) {
      setTimeout(() => {
        fileRefs.current[foundKey].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 200);
    }
  }, [scrollToFileRef, doesMatchScrollRef, finalData, setCollapsed]);

  return (
    <>
      {/* Generic overlay for file viewing */}
      {overlayFile && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setOverlayFile(null)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              maxWidth: '80%',
              maxHeight: '90%',
              overflow: 'auto',
              cursor: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{overlayFile.name}</h2>
            {overlayFile.type && overlayFile.type.toLowerCase() === 'pdf' ? (
              <>
                <MinimalPDFViewer pdfUrl={overlayFile.link} />
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <a href={overlayFile.link} download>
                    <button>Download PDF</button>
                  </a>
                </div>
              </>
            ) : (
              <>
                <p>Type: {overlayFile.type ? overlayFile.type.toUpperCase() : 'N/A'}</p>
                <p>
                  {overlayFile.link ? (
                    <a
                      href={overlayFile.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download or View File
                    </a>
                  ) : (
                    <span>No link available.</span>
                  )}
                </p>
              </>
            )}
            <button onClick={() => setOverlayFile(null)} style={{ marginTop: '10px' }}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* Dedicated Pedagogical Outcomes overlay */}
      {showPedagogyOverlay && (
        <PedagogicalOutcomesOverlay onClose={() => setShowPedagogyOverlay(false)} />
      )}

      <div className={`file-menu-right ${isCollapsed ? 'collapsed' : ''}`}>
        <button
          className="collapse-button"
          onClick={() => setCollapsed((prev) => !prev)}
          title={isCollapsed ? 'Show Files' : 'Hide Files'}
        >
          {isCollapsed ? '>' : '<'}
        </button>

        {/* Pinned Files Section */}
        <div className="pinned-file-wrapper">
          <div className="pinned-header">📌 Pinned Files</div>
          {finalData.pinnedFiles.length === 0 && (
            <div style={{ fontSize: '12px', color: '#999' }}>
              No pinned files found.
            </div>
          )}
          {finalData.pinnedFiles.map((fileItem) => {
            const iconClass =
              fileItem.type === 'pin'
                ? 'pinnedfile-icon'
                : fileItem.type + '-icon';
            const iconLabel =
              fileItem.type === 'pin'
                ? ''
                : fileItem.type?.toUpperCase() || 'FILE';
            const highlight = shouldHighlight(fileItem.name, fileItem.link);
            const itemKey = `pinned-${fileItem.id}`;
            return (
              <div
                className="file-item"
                key={fileItem.id}
                ref={(el) => (fileRefs.current[itemKey] = el)}
                onClick={() => handleFileClick(fileItem)}
                style={{
                  border: highlight ? '2px solid green' : 'none',
                  transition: 'border 0.3s',
                }}
              >
                <div className={`file-icon ${iconClass}`}>{iconLabel}</div>
                <div className="file-name">{fileItem.name}</div>
              </div>
            );
          })}
        </div>

        <hr className="file-menu-divider" />

        {/* Category-Based Files */}
        {finalData.categories.length === 0 && (
          <div style={{ fontSize: '12px', color: '#999', padding: '0 15px' }}>
            No categories found.
          </div>
        )}
        {finalData.categories.map((category, catIdx) => (
          <div key={catIdx}>
            <h2 className="file-menu-title">{category.title}</h2>
            {category.weeks.map((week, weekIdx) => {
              const hasMatch = week.files.some((f) => matchesSearch(f.name));
              if (!hasMatch && searchTerm.trim() !== '') {
                return null;
              }
              const wkKey = `${catIdx}-${weekIdx}`;
              const isExpanded = expandedWeeks[wkKey] || false;
              return (
                <div key={weekIdx} className="week-wrapper">
                  <div
                    className="subheading"
                    onClick={() => toggleWeek(catIdx, weekIdx)}
                  >
                    <span>{getTopicTitle(week.weekTitle, weekIdx)}</span>
                    <span>{isExpanded ? '[-]' : '[+]'}</span>
                  </div>
                  {useUpdatedFileMenu && (
                    <div
                      className="clear-outcomes"
                      style={{
                        margin: '10px 0',
                        fontSize: '12px',
                        color: '#555',
                      }}
                    >
                      Clear Outcomes: <a href={`#outcomes-${catIdx}-${weekIdx}`}>View Details</a>
                    </div>
                  )}
                  <div className={`week-files ${isExpanded ? 'expanded' : ''}`}>
                    {week.files
                      .filter((fi) => matchesSearch(fi.name))
                      .map((fi, fIdx) => {
                        const iconClass = fi.type + '-icon';
                        const iconLabel = fi.type?.toUpperCase() || 'FILE';
                        const highlight = shouldHighlight(fi.name, fi.link);
                        const itemKey = `cat-${catIdx}-week-${weekIdx}-file-${fIdx}`;
                        return (
                          <div
                            className="file-item"
                            key={fIdx}
                            ref={(el) => (fileRefs.current[itemKey] = el)}
                            onClick={() => handleFileClick(fi)}
                            style={{
                              border: highlight ? '2px solid green' : 'none',
                              transition: 'border 0.3s',
                            }}
                          >
                            <div className={`file-icon ${iconClass}`}>
                              {iconLabel}
                            </div>
                            <div className="file-name">{fi.name}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        ))}

        {/* Search Bar */}
        <div className="search-container">
          <input
            type="text"
            className="search-bar"
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

export default FileMenu;
