// MinimalPDFViewer.js
import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// Use the local worker from your public folder.
pdfjsLib.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.min.js';

function MinimalPDFViewer({ pdfUrl }) {
  const canvasRef = useRef(null);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [error, setError] = useState(null);

  // Load the PDF document when pdfUrl changes.
  useEffect(() => {
    let isMounted = true;
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    loadingTask.promise
      .then((loadedPdf) => {
        if (!isMounted) return;
        setPdfDoc(loadedPdf);
        setPageCount(loadedPdf.numPages);
        setPageNumber(1);
      })
      .catch((err) => {
        if (isMounted) setError(err.message);
      });
    return () => {
      isMounted = false;
    };
  }, [pdfUrl]);

  // Render the current page on the canvas.
  useEffect(() => {
    if (!pdfDoc || pageNumber < 1 || pageNumber > pageCount) return;
    let isMounted = true;
    pdfDoc.getPage(pageNumber).then((page) => {
      if (!isMounted || !canvasRef.current) return;
      const viewport = page.getViewport({ scale: 1.2 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({ canvasContext: context, viewport });
    });
    return () => {
      isMounted = false;
    };
  }, [pdfDoc, pageNumber, pageCount]);

  const goToPrevPage = () => {
    setPageNumber((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const goToNextPage = () => {
    setPageNumber((prev) => (prev < pageCount ? prev + 1 : prev));
  };

  if (error) {
    return (
      <div style={{ color: 'red' }}>
        <strong>Error loading PDF:</strong> {error}
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {!pdfDoc && <div>Loading PDF...</div>}
      {pdfDoc && (
        <>
          <canvas ref={canvasRef} style={{ border: '1px solid #ccc' }} />
          <div style={{ marginTop: '10px' }}>
            <p>
              Page {pageNumber} of {pageCount}
            </p>
            <button onClick={goToPrevPage} disabled={pageNumber <= 1}>
              Previous
            </button>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= pageCount}
              style={{ marginLeft: '10px' }}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MinimalPDFViewer;
